'use-client';

import useScreen from 'hooks/useScreen';
import PropTypes from 'prop-types';

const DesktopOnlyLegacy = ({ children, fallback = null }) => {
  const { isDesktop } = useScreen();

  return isDesktop ? children : fallback;
};

DesktopOnlyLegacy.propTypes = {
  children: PropTypes.node,
};

export default DesktopOnlyLegacy;
